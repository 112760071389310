
import "./Data.scss";
import AOS from "aos";
import { useEffect,useState,useRef} from "react";
import { FaShieldVirus, FaUserShield, FaHandshake, FaBusinessTime, FaMoneyBillWave } from 'react-icons/fa';



const Data = ( ) =>{
  const clientSliderRef = useRef(null);
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);

        // State to track the active tab index
  const [activeIndex, setActiveIndex] = useState(1);

  // Function to handle tab click
  const handleTabClick = (index) => {
    setActiveIndex(index);
  };


  useEffect(() => {
    const clientSlider = clientSliderRef.current;
    
    const interval = setInterval(() => {
      clientSlider.style.transform = "translateX(-20%)";
    }, 3000);

    clientSlider.addEventListener('transitionend', function () {
      clientSlider.appendChild(clientSlider.firstElementChild);
  
      clientSlider.style.transition = "none";
      clientSlider.style.transform = "translateX(0)";
      setTimeout(() => {
        clientSlider.style.transition = "0.3s";
      });
    });

    return () => clearInterval(interval);
  }, []);

    return(
        <>
        <div className="about_text" > 
         <img className="aboutimg" src= './da.jpg' alt="xyz" />
            <div className="upperdiv"></div>
            <div className="about"  data-aos="fade-up">
              <h1>Data Security</h1>
            </div>
         
        </div>



     <div className="parallax-container2" style={{backgroundImage: "url('./dataa.jpg')"}}>
       
       <div className="parallax-image-right2">
      
     </div>
       <div className="text-container2" data-aos="fade-up">
         <div className="about-us_home2">
           <h2>WHY DATA SECURITY?</h2>
           <p>
    Data security is crucial to protect sensitive information from unauthorized access, 
    breaches, or loss. It safeguards privacy, maintains trust, and ensures the integrity of digital assets, preventing potential financial and reputational damage.
    </p>
         </div>
       </div>
     </div>



  <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Data "}
            <span className="onlyc">s</span>
            <span>ecurity</span>
        
          <p>solutions we offer in Data Security</p>
        </h2>

        
      </div>




      <div className="cyber-container">
      <nav className="cyber-nav">
        <ul className="cyber-ul">

          <li className={activeIndex === 1 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(1)}>
          <i className="fa fa-user-secret"></i> </li>
         
          <li className={activeIndex === 2 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(2)}>
            <i className="fa fa-lock"></i></li>

          <li className={activeIndex === 3 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(3)}>
            <i className="fa fa-envelope"></i></li>

            <li className={activeIndex === 4 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(4)}>
            <i className="fa fa-shield"></i></li>

            <li className={activeIndex === 5 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(5)}>
            <i className="fa fa-cloud-upload"></i></li>

        </ul>
      </nav>


      <article className="cyber-article" style={{ display: activeIndex === 1 ? 'block' : 'none' }}>
        <h1>Data Leakage Prevention</h1>
        <p>Protect your sensitive data from unauthorized access and leakage with our robust Data Leakage Prevention (DLP) solutions. Our comprehensive DLP measures include real-time monitoring, policy enforcement, and threat detection to ensure the confidentiality and integrity of your critical information.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>                           
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 2 ? 'block' : 'none' }}>
        <h1>Encryption</h1>
        <p>Ensure the security and privacy of your data in transit and at rest with our advanced Encryption solutions. Utilizing industry-standard encryption algorithms and key management practices, we encrypt your sensitive information to prevent unauthorized access and maintain compliance with regulatory requirements.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 3 ? 'block' : 'none' }}>
        <h1>Email Security</h1>
        <p>Protect your email communication from phishing attacks, malware threats, and data breaches with our robust Email Security solutions. Our comprehensive email security measures include spam filtering, attachment scanning, encryption, and user authentication to safeguard your organization's sensitive information and maintain email continuity.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 4 ? 'block' : 'none' }}>
        <h1>VPN Solution</h1>
        <p>Securely connect your remote workforce and branch offices to your corporate network with our reliable VPN (Virtual Private Network) Solution. Our VPN services offer end-to-end encryption, secure tunneling, and multi-factor authentication to ensure secure access to your critical resources while maintaining privacy and compliance.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 5 ? 'block' : 'none' }}>
        <h1>Data Backup or Cloud Storage</h1>
        <p>Safeguard your valuable data against loss or corruption with our secure Data Backup and Cloud Storage solutions. Benefit from automated backups, redundant storage, and seamless recovery options to ensure uninterrupted access to your files and applications, anytime and anywhere.
</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>
      
      
    </div>


    <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Products "}
            <span className="onlyc">S</span>
            <span>olutions</span>
         
          <p>Products we work with in DATA SECURITY</p>
        </h2>      
    </div>
      
    <section className="our-clients">
      <div className="container">

        <ul ref={clientSliderRef} id="client-slider" className="client-slider">

        <li><img src="gbt.png" alt="Client 4 Logo" /></li>
        <li><img src="sophos.png" alt="Client 2 Logo" /></li>
        <li><img src="fortinet.png" alt="Client 3 Logo" /></li>
        <li><img src="TrendMicro.png" alt="Client 1 Logo" /></li>
          <li><img src="seqrite.png" alt="Client 4 Logo" /></li>
         <li><img src="aws.jpg" alt="Client 1 Logo" /></li>
         <li><img src="acronis.png" alt="Client 2 Logo" /></li>
          <li><img src="Azure.jpg" alt="Client 2 Logo" /></li>
          <li><img src="Druva.png" alt="Client 2 Logo" /></li>
        <li><img src="Forcepoint.png" alt="Client 3 Logo" /></li>
          
         
          
         
      
        </ul>
      </div>
    </section>

<div className="sss">
    <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Benefits of "}
            <span className="onlyc">D</span>
            <span>ata Security</span>
          
          <p>Unveiling the Benefits of Data Security</p>
        </h2>      
    </div>
    </div>

<div className='benifts'>
  <div className='beniftsUL'>
    <ul className="cyber-ul">


    <li style={{ '--accent-color': '#60c5a6' }}>
  <div className="icon"><FaShieldVirus /></div>
  <div className="title">Threats Protection</div>
  <div className="descr">Safeguard sensitive data from unauthorized access, preventing malware, ransomware, and phishing attacks.</div>
</li>
<li style={{ '--accent-color': '#f20071' }}>
  <div className="icon"><FaUserShield /></div>
  <div className="title">Customer Assurance</div>
  <div className="descr">Strengthen customer confidence in your organization's ability to protect their personal information, fostering long-term relationships.</div>
</li>
<li style={{ '--accent-color': '#FCBA35' }}>
  <div className="icon"><FaHandshake /></div>
  <div className="title">Trust and Reputation</div>
  <div className="descr">Build trust among customers and stakeholders by demonstrating a commitment to data privacy and security.</div>
</li>
<li style={{ '--accent-color': '#DAE438' }}>
  <div className="icon"><FaBusinessTime /></div>
  <div className="title">Business Continuity</div>
  <div className="descr">Minimize disruptions to operations and maintain continuity in the event of data breaches or cyber incidents.</div>
</li>
<li style={{ '--accent-color': '#994D7F' }}>
  <div className="icon"><FaMoneyBillWave /></div>
  <div className="title">Cost Efficiency</div>
  <div className="descr">Reduce financial losses associated with data breaches, including legal fees, regulatory fines, and reputational damage.</div>
</li>


    </ul>
  </div>
 
</div>


        </>
    );
};
export default Data;



