import "./Cards.scss";
import { Button, Card, Container, Carousel, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useRef, useState, useEffect } from "react";
import "animate.css/animate.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {
  faUsers,
  faTasks,
  faClock,
  faSmile,
} from "@fortawesome/free-solid-svg-icons";
import { MDBCardBody, MDBIcon } from "mdb-react-ui-kit";
import "swiper/css";
import "swiper/css/pagination";
import { useForm } from "react-hook-form";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import About from "./pages/About";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ReCAPTCHA from "react-google-recaptcha";

const Cardd = ({ imgSrc, authorName, snippet, key }) => {
  return (
    <figure className="snip1232" key={key}>
      <div className="author">
        <img src={imgSrc} alt={authorName} />
        <h5>{authorName}</h5>
      </div>
      <blockquote>{snippet}</blockquote>
    </figure>
  );
};

function Cards() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  //contact
  const form = useRef();
  const { register, handleSubmit, formState, reset } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

   // Function to handle reCAPTCHA
   const handleRecaptchaChange = (value) => {
    setRecaptchaToken(value);
  };

  const handleAlertAndReset = async (data) => {
    if (!recaptchaToken) {
      alert("Please complete the CAPTCHA.");
      return;
    }

    try {
      const response = await fetch("https://3handshake.com/backend/contact-save.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...data, recaptchaToken }),
      });

      if (response.ok) {
        setShowAlert(true);
        reset();
      } else {
        alert("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  // const handleAlertAndReset = () => {
  //   setShowAlert(true); // Show success alert
  //   reset(); // Reset form
  // };



  
  const Cardd = ({ imgSrc, authorName, snippet, index_key }) => {
    return (
      <div className="d-flex justify-content-center" key={index_key}>
        <figure className="snip1232">
          <div className="author">
            <img src={imgSrc} alt={authorName} />
            <h3 className="h5">{authorName}</h3>
          </div>
          <blockquote>{snippet}</blockquote>
        </figure>
      </div>
    );
  };

  const cardsData = [
    {
      imgSrc: "./t1.png",
      authorName: "Expert Counsel",
      snippet:
        "Our commitment to providing personalized guidance from seasoned industry leaders.",
    },
    {
      imgSrc: "./t2.png",
      authorName: "Affordable Services",
      snippet:
        " Experience the assurance of quality paired with affordability in our services, ensuring value without compromise.",
    },
    {
      imgSrc: "./t3.png",
      authorName: "Skilled Engineers",
      snippet:
        " Rely on our certified and skilled engineers for precision and expertise, ensuring top-notch solutions tailored to your needs.",
    },
    {
      imgSrc: "./t4.png",
      authorName: "Complete Solutions ",
      snippet:
        "Elevate operations with our integrated solutions for efficient and innovative success.",
    },
    {
      imgSrc: "./t5.png",
      authorName: "Focus on Client ROI",
      snippet:
        "We prioritize client ROI, ensuring consistent focus on delivering value and measurable returns.",
    },
    {
      imgSrc: "./t6.png",
      authorName: "Business Alliances",
      snippet:
        " Forge strategic business alliances, unlocking growth and opportunities for mutual success.",
    },
    // Add more cards as needed
  ];

  // slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 3, // Default number of slides visible
    slidesToScroll: 1,
    autoplay: true, // Enable auto slide
    autoplaySpeed: 2000, // Auto slide interval in milliseconds (3 seconds)
    responsive: [
      {
        breakpoint: 1024, // For tablets and small desktops
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true, // Ensure autoplay for this breakpoint
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 600, // For mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          autoplay: true, // Ensure autoplay for this breakpoint
          autoplaySpeed: 2000,
        },
      },
    ],
  };
  //  end slider

  return (
    <>
      {/* About US Code */}

      <section className="p-0  container">
        <div className="Achi_text" data-aos="zoom-out">
          <div className="section-fisrt-title">
              <p className="p-to-h1">
                <a href="/Contact" id="style-2" data-replace="Expertise">
                  {"Who "}
                  <span className="onlyc">W</span>
                  <span>e Are </span>
                </a>
              </p>
              <h1 className="fix-size-16">We Made Technology Simple for You</h1>
          </div> 
        </div>
        <div className="row text-left text-md-left mx-2 pb-5  d-flex  align-items-center justify-content-center">
          <div className="col-md-6 d-none d-md-block">
            <img
              className="rounded img-fluid"
              src="./tyty.jpg"
              alt="about page "
            />
          </div>
          <div className="col-md-6 ">
            <div className="pt-2">
              <p>
                3HS offers an all-encompassing provider for IT security services
                in Jaipur. With years of experience, 3Handshake Security
                Services offers reliable and professional security solutions for
                their business clients across Jaipur. Whether its cyber
                security, data security, networking solutions, hardware
                solutions, software solutions, cloud solutions, or managed IT
                services, maintenance, and consulting, our highly trained staff
                provide the best solutions in Jaipur.
              </p>
              <p>
                3HS has worked with several international clients and
                organizations. A competent group of network and security
                experts, technical support executives, and hardware
                professionals make up our company.
              </p>
              <p><Link to={'Contact'} className="btn btn-first" >Read More </Link></p>
            </div>
          </div>
        </div>

        {/* <div
            className="parallax-container"
            style={{ backgroundImage: "url('./tyty.jpg')" }}
          >
            <div className="parallax-image-right"></div>
            <div className="text-container" data-aos="fade-up">
              <div className="about-us_home text-center">
                <h1 className="h2">We Made Technology Simple for You</h1>
                <h4>
                  <b>Who We Are</b>
                </h4>
                <p>
                  3HS offers an all-encompassing provider for IT security services
                  in Jaipur. With years of experience, 3Handshake Security Services
                  offers reliable and professional security solutions for their
                  business clients across Jaipur. Whether its cyber security, data
                  security, networking solutions, hardware solutions, software
                  solutions, cloud solutions, or managed IT services, maintenance,
                  and consulting, our highly trained staff provide the best
                  solutions in Jaipur.
                </p>
                <p>
                  3HS has worked with several international clients and
                  organizations. A competent group of network and security experts,
                  technical support executives, and hardware professionals make up
                  our company.{" "}
                </p>
                <div className="text-right d-flex justify-content-end pb-2">
                  <Link className="btn text-right btn-first" to={"about"}>
                    Read More..
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
      </section>

      {/* end About us */}

      <section className="p-0" id="timeline">
        <div className="Achi_text" data-aos="zoom-in">
          <div className="section-fisrt-title">
                <h2>
                  <a href="/Services" id="style-2" data-replace="Expertise">
                    {"Our "}
                    <span className="onlyc">S</span>
                    <span>ervices</span>
                  </a>
                </h2>
                <p>We can help you with...</p>
            </div> 
        </div>

        <section class="section services-section" id="services">
          <div class="container">
            <div class="row">
              <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                  <div class="icon">
                    <i class="fa  fa-shield"></i>
                  </div>
                  <div class="feature-content">
                    <h3>
                      <a href="/Cyber">Cyber Security </a>
                    </h3>
                    <p>
                      To secure your cyber threats we offer robust Firewall/UTM
                      systems, Endpoint Security, DDoS Protection and cyber
                      security in Jaipur.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                  <div class="icon">
                    <i class="fa fa-lock"></i>
                  </div>
                  <div class="feature-content">
                    <h3>
                      <a href="/Data">Data Security </a>
                    </h3>
                    <p>
                      We understand your Data Safety so we provide Data security
                      services like Data Leakage Prevention, reliable Data
                      Backup or ...
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                  <div class="icon">
                    <i class="fas fa-globe"></i>
                  </div>
                  <div class="feature-content">
                    <h3>
                      <a href="/Network">Network & Security </a>{" "}
                    </h3>
                    <p>
                      To track every activity and to manage a seamless and
                      secure environment for your business, we provide
                      Active-Passive Network Design,..
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                  <div class="icon">
                    <i class="fas fa-cloud"></i>
                  </div>
                  <div class="feature-content">
                    <h3>
                      <a href="/Cloud">Cloud Solutions </a>
                    </h3>
                    <p>
                      Our offerings include Cloud Managed Services, Cloud
                      Hosting, Web Services, Web Hosting, and Web-Server
                      solutions, ensuring flexibility,..
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                  <div class="icon">
                    <i class="fas fa-lightbulb"></i>
                  </div>
                  <div class="feature-content">
                    <h3>
                      {" "}
                      <a href="/Software">Software Solutions</a>
                    </h3>
                    <p>
                      To enhance security across your organization, we provide
                      Office 365 Email, Software Licensing, Employee Monitoring
                      Tools, Payroll Software, ...
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                  <div class="icon">
                    <i class="fa fa-cog"></i>
                  </div>
                  <div class="feature-content">
                    <h3>
                      {" "}
                      <a href="/Hardware">Hardware Solutions </a>
                    </h3>
                    <p>
                      What you need for your business - from top-quality
                      Desktops, Laptops, Servers, Gaming PCs, to Storage
                      Devices, and Computer Peripherals,...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div className="demo-card-wrapper" >
		<div className="demo-card demo-card--step1" data-aos="fade-right">
			<div className="head">
				<div className="number-box" >
					<span>01</span>
				</div>
				<h3 ><a href="/Cyber">Cyber Security </a></h3>
			</div>
			<div className="body">
				<p>To secure your cyber threats we offer robust Firewall/UTM systems, Endpoint Security, DDoS Protection and cyber security in Jaipur. 
        </p>
				<img src="./cyber.jpeg" alt="Graphic"/>
			</div>
		</div>

		<div className="demo-card demo-card--step2"  data-aos="fade-left">
			<div className="head">
				<div className="number-box">
					<span>02</span>
				</div>
				<h3><a href="/Data">Data Security </a></h3>
			</div>
			<div className="body">
				<p>We understand your Data Safety so we provide Data security services like Data Leakage Prevention, reliable Data Backup or Cloud Storage, Encryption, Email Security, Access Control, and VPN Solutions by ensuring your critical information is accessible only to authorized users.
        </p>
				<img src="cyber2.jpeg" alt="Graphic"/>
			</div>
		</div>

		<div className="demo-card demo-card--step3" data-aos="fade-right">
			<div className="head">
				<div className="number-box">
					<span>03</span>
				</div>
				<h3><a href="/Network">Network & Security </a> </h3>
			</div>
			<div className="body">
				<p>To track every activity and to manage a seamless and secure environment for your business, we provide Active-Passive Network Design, Wireless Solutions, CCTV Surveillance, and Biometric Systems services in Jaipur. 
        </p>
				<img src="net.jpg" alt="Graphic"/>
			</div>
		</div>

		<div className="demo-card demo-card--step4" data-aos="fade-left">
			<div className="head">
				<div className="number-box">
					<span>04</span>
				</div>
				<h3><a href="/Cloud">Cloud Solutions </a></h3>
			</div>
			<div className="body">
				<p>- Our offerings include Cloud Managed Services, Cloud Hosting, Web Services, Web Hosting, and Web-Server solutions, ensuring flexibility, performance, and reliable uptime for your digital assets.
        </p>
				<img src="cloud.jpg" alt="Graphic"/>
			</div>
		</div>

		<div className="demo-card demo-card--step5" data-aos="fade-right">
			<div className="head">
				<div className="number-box">
					<span>05</span>
				</div>
				<h3> <a href="/Software">Software Solutions</a></h3>
			</div>
			<div className="body">
				<p>To enhance security across your organization, we provide Office 365 Email, Software Licensing, Employee Monitoring Tools, Payroll Software, and Antivirus solutions.
        </p>
				<img src="software.jpg" alt="Graphic"/>
			</div>
		</div>

        <div className="demo-card demo-card--step6" data-aos="fade-left">
			<div className="head">
				<div className="number-box">
					<span>06</span>
				</div>
				<h3> <a href="/Hardware">Hardware Solutions </a></h3>
			</div>
			<div className="body">
				<p> What you need for your business - from top-quality Desktops, Laptops, Servers, Gaming PCs, to Storage Devices, and Computer Peripherals, we supply your business's computing and storage needs efficiently.
        </p>
				<img src="hardware.avif" alt="Graphic"/>
			</div>
		</div>
    
	</div> */}
      </section>

      <div className="Achi_text" data-aos="fade-up">
          <div className="section-fisrt-title">
              <h2>
                <a>
                  {"Why "}
                  <span className="onlyc">C</span>
                  <span>hoose Us</span>
                </a>
              </h2>
              <p>Empowering Excellence</p>
          </div> 
        <h2>
        </h2>
      </div>

      <div className="cardd-container" data-aos="fade-up">
        {cardsData.map((cardd, index) => (
          <Cardd
            imgSrc={cardd.imgSrc}
            authorName={cardd.authorName}
            snippet={cardd.snippet}
            key={index}
            data-aos={cardd.aos}
          />
        ))}
      </div>

      {/* Our Assurance */}
      <div className="Achi_text" data-aos="zoom-in">
        <div className="section-fisrt-title" >
          <h2 className="onlyt">
            <a>
              {"Our "}
              <span>A</span>
              <span>ssurance </span>
            </a>
          </h2>
            <p>Your Confidence, Our Priority</p>
        </div>
      </div>

      <div className="achievement pb-5">
        <div className="achievements-container">
          <div className="achievement-card">
            <FontAwesomeIcon icon={faUsers} className="achievement-icon" />
            <h3>Clients Served </h3>
            <h4>1000+</h4>
          </div>
          <div className="achievement-card">
            <FontAwesomeIcon icon={faTasks} className="achievement-icon" />
            <h3>Projects Completed</h3>
            <h4> 103+</h4>
          </div>
          <div className="achievement-card">
            <FontAwesomeIcon icon={faClock} className="achievement-icon" />
            <h3>Experience Hours</h3>
            <h4> 11k+</h4>
          </div>
          <div className="achievement-card">
            <FontAwesomeIcon icon={faSmile} className="achievement-icon" />
            <h3>Client Satisfaction</h3>
            <h4> 98.67%</h4>
          </div>
        </div>
      </div>

      {/* Lead button  */}
      <section className="p-0 lead-secton">
        <div className="my-4 container">
          <div className="row align-items-center justify-content-center py-4 text-center">
            <div className="col-md-6 ">
               <h4>Secure Your Premises with 3Handshake</h4>
              <p className="text-white">Protect your server with 3Handshake’s advanced security solutions. Our smart, reliable technology ensures complete peace of mind with cutting-edge surveillance and monitoring systems.</p>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <a href="tel:917229915333" className="btn btn-second mt-2 px-4 py-3">
                Request a Call Now
              </a>
            </div>
          </div>
          <div></div>
        </div>
      </section>
      {/* End Lead button  */}

      <div className="Achi_text" data-aos="zoom-in">
        <div className="section-fisrt-title">
          <h2>
            <a>
              {"Our "}

              <span className="onlyc">C</span>
              <span>lients</span>
            </a>
          </h2>
            <p>Our Proud Journey</p>
        </div>
      </div>

      <Carousel className="Carousel" fade>
        <Carousel.Item interval={1000}>
          <Card.Img src="./cl1.png" />
        </Carousel.Item>
        <Carousel.Item interval={500}>
          <Card.Img src="./cl22.png" />
        </Carousel.Item>
      </Carousel>

      <div className="Achi_text" data-aos="zoom-in">
        <div className="section-fisrt-title"> 
            <h2>
              <a>
                {"What "}

                <span className="onlycc">O</span>
                <span>ur Client Say</span>
              </a>
            </h2>
              <p>Our Clients Feedback</p>
        </div>
      </div>
      <div className="container">
        <Slider {...settings} className="width-vw-100">
            <div class="testimonial">
              <p class="description"><i class="fas fa-quote-left"></i> We trust 3HS for their unwavering commitment to security. Their
                solutions ensure the confidentiality and integrity of our
                financial data. <i class="fas fa-quote-right"></i>
              </p>
              <p className="text-warning">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star-half"></i>
              </p>
              <div>
                  <h3 class="title">
                    Mr. Shashank Mathur
                  </h3>
                    <p class="post">
                      - Sterling Urban Co-operative Bank Ltd.
                    </p>
              </div>   
            </div>

            <div class="testimonial">
          
              <p class="description">
              <i class="fas fa-quote-left"></i> 3Handshake Innovation Pvt. Ltd. has been our trusted IT support
                for years they delivered cost-effective solutions without
                compromising quality. They consistently provide quick,
                effective, and reliable IT solutions. <i class="fas fa-quote-right"></i>
              </p>
              <p className="text-warning">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
              </p>
              <h3 class="title">
                Mr. Sachin Bhargav
              </h3>
                <p class="post">
                  - Process & Machines Automation Systems
                </p>
            </div>

            <div class="testimonial">
            
              <p class="description">
              <i class="fas fa-quote-left"></i> 3Handshake Innovation Pvt. Ltd. consistently provide quick,
                effective, and reliable IT solutions makes them our favorite 
                service provider. <i class="fas fa-quote-right"></i>
              </p>
              <p className="text-warning">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star-half"></i>
              </p>
              <h3 class="title">
                Mr. Arpit Vijayvargiya
              </h3>
                <p class="post">- Art India</p>
            </div>

            <div class="testimonial">
             
              <p class="description">
              <i class="fas fa-quote-left"></i> 3HS delivers excellence! The services of this company are very
                fast and the quick response time and a complete suite of all IT 
                solutions. <i class="fas fa-quote-right"></i>
              </p>
              <p className="text-warning">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star-half"></i>
              </p>
              <h3 class="title">
                Mr. Mohit Bahl
              </h3>
                <p class="post">- Cogent 360 Solutions Pvt. Ltd.</p>
            </div>

            <div class="testimonial">
            
              <p class="description">
              <i class="fas fa-quote-left"></i> We are very satisfied with the services of 3HS. They have well
                trained IT staff who provide us with IT and Cyber solutions. 3HS
                exceeds our expectations. <i class="fas fa-quote-right"></i>
              </p>
              <p className="text-warning">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star-half"></i>
              </p>
              <h3 class="title">
                Mr. Prateek Chaubey
              </h3>
                <p class="post">- iGlobe Solutions</p>
            </div>
            <div class="testimonial">
              
              <p class="description">
              <i class="fas fa-quote-left"></i> 3Handshake Innovation Pvt. Ltd. 5-star expertise in camera,
                biometric, hardware, software, networking, and cloud solutions,
                benefiting Danish Pvt. Ltd. <i class="fas fa-quote-right"></i>
              </p>
              <p className="text-warning">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
              </p>
              <h3 class="title">
                Mr. Sachin Sharma
              </h3>
                <p class="post">- Danish Pvt. Ltd. </p>
            </div>
        </Slider>
      </div>

      {/* Contact */}
      <Container className="contact-page-sec">
      <div className="contactpage">
        <div className="Achi_text" data-aos="zoom-in">
          <h2>
            <a href="/contact">
              Contact <span className="onlycc">U</span>
              <span>s</span>
            </a>
            <p>Get In Touch</p>
          </h2>
        </div>

        {showAlert && (
          <Alert
            className="mt-4"
            variant="success"
            onClose={() => setShowAlert(false)}
            dismissible
          >
            Response recorded, Thank You!
          </Alert>
        )}

        <Form ref={form} onSubmit={handleSubmit(handleAlertAndReset)} className="p-4" >
          <Form.Group controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="user_name"
              placeholder="Enter your name"
              {...register("name", { required: "Name is required" })}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="user_email"
              placeholder="Enter email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              })}
            />
          </Form.Group>

          <Form.Group controlId="formBasicMobile">
            <Form.Label>Mobile No.</Form.Label>
            <Form.Control
              type="tel"
              name="user_mobile"
              placeholder="Enter Mobile"
              {...register("mobile", {
                required: "Mobile is required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Invalid mobile number",
                },
              })}
            />
          </Form.Group>

          <Form.Group controlId="formBasicMessage">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              name="message"
              rows={5}
              placeholder="Enter your message"
              {...register("message", {
                required: "Message is required",
              })}
            />
          </Form.Group>

          {/* Google reCAPTCHA */}
          <ReCAPTCHA
            sitekey="6Lfd76MqAAAAALIfXfy50j0QBsizQaVUIwY1MWZC" // Replace with your actual site key
            onChange={handleRecaptchaChange}
            className="mt-4"
          />

          <Button
            className="btn btn-first mt-4"
            variant="primary"
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </Form>
      </div>
    </Container>
    </>
  );
}

export default Cards;
