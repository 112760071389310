import "./Software.scss";
import AOS from "aos";
import { useEffect,useState,useRef} from "react";
import { FaTasks, FaUsers, FaChartLine, FaExchangeAlt, FaMoneyCheckAlt } from 'react-icons/fa';


const Software = ( ) =>{
  const clientSliderRef = useRef(null);
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);

        // State to track the active tab index
  const [activeIndex, setActiveIndex] = useState(1);

  // Function to handle tab click
  const handleTabClick = (index) => {
    setActiveIndex(index);
  };


  useEffect(() => {
    const clientSlider = clientSliderRef.current;
    
    const interval = setInterval(() => {
      clientSlider.style.transform = "translateX(-20%)";
    }, 3000);

    clientSlider.addEventListener('transitionend', function () {
      clientSlider.appendChild(clientSlider.firstElementChild);
  
      clientSlider.style.transition = "none";
      clientSlider.style.transform = "translateX(0)";
      setTimeout(() => {
        clientSlider.style.transition = "0.3s";
      });
    });

    return () => clearInterval(interval);
  }, []);

    return(
        <>
        <div className="about_text" > 
         <img className="aboutimg" src= './soft.jpg' alt="xyz" />
            <div className="upperdiv"></div>
            <div className="about"  data-aos="fade-up">
              <h1>Software Solutions</h1>
            </div>
         
        </div>



     <div className="parallax-container2" style={{backgroundImage: "url('./softbg.jpg')"}}>
       
       <div className="parallax-image-right2">
      
     </div>
       <div className="text-container2" data-aos="fade-up">
         <div className="about-us_home2">
           <h2>WHAT IS SOFTWARE SOLUTIONS? </h2>
           <p>
           A software solution is a tailored program or application designed to address specific challenges or meet particular needs. It provides a digital answer to a problem, streamlining processes, enhancing efficiency, and improving overall performance within a given context.
            </p>
         </div>
       </div>
     </div>



  <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Software "}
            <span className="onlyc">S</span>
            <span>olutions</span>
        
          <p>solutions we offer in Software Solutions</p>
        </h2>

        
      </div>

    <div className="cyber-container">
      <nav className="cyber-nav">
        <ul className="cyber-ul">

          <li className={activeIndex === 1 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(1)}>
          <i className="fa fa-envelope"></i></li>
      
          <li className={activeIndex === 2 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(2)}>
          <i className="fa fa-key"></i></li>

          <li className={activeIndex === 3 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(3)}>
          <i className="fa fa-eye"></i></li>

            <li className={activeIndex === 4 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(4)}>
            <i className="fa fa-file"></i></li>

            <li className={activeIndex === 5 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(5)}>
            <i className="fa fa-shield"></i></li>

        </ul>
      </nav>
      <article className="cyber-article" style={{ display: activeIndex === 1 ? 'block' : 'none' }}>
        <h1>Office 365 Email</h1>
        <p>Elevate your communication and collaboration with Office 365 Email solutions. Enjoy the convenience of accessing emails, calendars, and contacts from anywhere, at any time, with seamless integration across devices. Benefit from advanced security features, reliable uptime, and scalable plans tailored to your business needs. </p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>                           
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 2 ? 'block' : 'none' }}>
        <h1>Software Licensing</h1>
        <p>Ensure compliance and maximize efficiency with our comprehensive software licensing services. Gain access to a wide range of industry-leading software products, including operating systems, productivity suites, and specialized tools. Our experts will help you select the right licensing options for your organization, ensuring cost-effectiveness and legal compliance. </p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 3 ? 'block' : 'none' }}>
        <h1>Employee Monitoring Tools</h1>
        <p>Enhance productivity and accountability with our employee monitoring tools. Gain valuable insights into employee activities, productivity trends, and resource utilization to optimize workflows and drive performance. Our customizable monitoring solutions offer real-time visibility and reporting capabilities to support informed decision-making and foster a productive work environment.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 4 ? 'block' : 'none' }}>
        <h1>Payroll Software</h1>
        <p>Simplify payroll management and streamline HR processes with our payroll software solutions. From automated payroll processing and tax calculations to employee self-service portals and compliance reporting, our intuitive software helps you save time, reduce errors, and ensure accurate payroll processing. Enjoy seamless integration with accounting systems and dedicated support for smooth implementation and ongoing assistance.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>


      <article className="cyber-article" style={{ display: activeIndex === 5 ? 'block' : 'none' }}>
        <h1>Antivirus</h1>
        <p>Protect your business-critical data and systems from cyber threats with our robust antivirus solutions. Shield your network, endpoints, and mobile devices from malware, ransomware, phishing attacks, and other security risks. Benefit from proactive threat detection, real-time updates, and centralized management tools to safeguard your digital assets and maintain business continuity.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>
      
      
    </div>

    <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Products "}
            <span className="onlyc">S</span>
            <span>olutions</span>
         
          <p>Products we work with in SOFTWARE SOLUTIONS</p>
        </h2>      
    </div>
      
    <section className="our-clients">
      <div className="container">

        <ul ref={clientSliderRef} id="client-slider" className="client-slider">
       

         <li><img src="saral.webp" alt="Client 1 Logo" /></li>
          <li><img src="desktrack.png" alt="Client 2 Logo" /></li>
        <li><img src="sophos.png" alt="Client 3 Logo" /></li>
          <li><img src="smartoffice.jpg" alt="Client 4 Logo" /></li>
          <li><img src="seqrite.png" alt="Client 1 Logo" /></li>
          <li><img src="QuickHeal.png" alt="Client 4 Logo" /></li>
          
        </ul>
      </div>
    </section>

<div className="sss">
    <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Benefits of "}
            <span className="onlyc">S</span>
            <span>oftware Solution</span>
          
          <p>Unveiling the Benefits of Software Solutions.</p>
        </h2>      
    </div>
    </div>

<div className='benifts'>
  <div className='beniftsUL'>
    <ul className="cyber-ul">


    <li style={{ '--accent-color': '#60c5a6' }}>
  <div className="icon"><FaTasks /></div>
  <div className="title">Enhanced Efficiency</div>
  <div className="descr">Upgrade to modern hardware for faster processing speeds and smoother multitasking, boosting overall system efficiency.</div>
</li>


<li style={{ '--accent-color': '#f20071' }}>
  <div className="icon"><FaUsers /></div>
  <div className="title">Improved Collaboration</div>
  <div className="descr">Foster seamless collaboration among teams with intuitive software platforms that facilitate communication, file sharing, and project management in real-time.</div>
</li>
<li style={{ '--accent-color': '#FCBA35' }}>
  <div className="icon"><FaChartLine /></div>
  <div className="title">Data-driven Insights</div>
  <div className="descr"> Harness the power of data analytics and reporting tools to gain actionable insights, make informed decisions, and drive business growth.</div>
</li>
<li style={{ '--accent-color': '#DAE438' }}>
  <div className="icon"><FaExchangeAlt /></div>
  <div className="title">Scalability and Adaptability</div>
  <div className="descr">Scale your operations effortlessly and adapt to changing business requirements with flexible software solutions that grow with your organization.</div>
</li>
<li style={{ '--accent-color': '#994D7F' }}>
  <div className="icon"><FaMoneyCheckAlt /></div>
  <div className="title">Cost Savings</div>
  <div className="descr">Reduce operational costs and overheads by replacing manual processes with efficient software solutions, optimizing resource allocation and maximizing ROI.</div>
</li>
    </ul>
  </div>
 
</div>


        </>
    );
};
export default Software;


