import "./About.scss";
//import TestimonialCard from './TestimonialCard';
import React, { useEffect} from "react";
import {
  MDBCardBody, MDBIcon,
} from "mdb-react-ui-kit";
import AOS from "aos";

function About(){
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);




    
    return(
        <>
        
<div className="about_text" > 

   <img className="aboutimg" src= './aboutpicc.jpg'alt="xyz" />
<div className="upperdiv"></div>
  <div className="aboutcontent"  data-aos="fade-up">
    <h1>About us</h1>
    
  </div>   
</div>




    
      <div className="card-section">

              <div className="image-sectionn ">
                <img src="./hands3.jpg" alt="About Us" />
              </div>
              <div className="text-section2">
                <h2>Story Behind Success..</h2>
                <p>
                In the vibrant field of technology three  Innovative mind comes  together to founded 
            3Handshake Innovations Pvt. Ltd. with a shared vision of providing comprehensive IT solutions. The trio united 
            their expertise in hardware and software under one roof. From humble beginnings, his commitment to excellence has 
            driven the company's growth, earning a reputation for innovation and customer satisfaction. The founders aspire to 
            take 3Handshake Innovations to MNC status, Which is a testament to their unwavering dedication to transforming dreams 
            into global IT success stories. <br/>
            <span className="textcom"> – 3Handshake Innovation Pvt. Ltd.</span>
                </p>
              </div>
      </div>



    <div className="Achi_text" data-aos="zoom-in">
  <h2>Our <a href="#" id="style-2" >
        <span className="onlyc">P</span>
          <span>rincipals</span></a><p>Pillars Of Purpose</p></h2>


      </div>
    <div className="mission-vision-values">
      <div className="sectionn">
          <div className="image-section">
            
              <img src="./mission.jpg" alt="Mission Image" />
              
            </div>
            
        <div className="contenttt">
          
          <h2>Our Mission</h2>
          <p>
          
Empowering Excellence: Our mission is to leverage expertise, uphold commitments, and foster an ecosystem that delivers affordable, comprehensive, and impactful solutions, ensuring client-centric success in every industry we serve.
          </p>
        </div>
        
      </div>

      <div className="sectionn">
            <div className="image-section">
                    <img src="./vision.jpg" alt="vission Image" />
                  </div>
        <div className="contenttt">
       

          <h2>Our Vision</h2>
          <p>
          Visionary Excellence: To achieve substantial growth, expand our global team, and enhance services, evolving into a customer-centric limited company that positively captures markets and sets new benchmarks for industry leadership.
          </p>
        </div>
       
      </div>

      <div className="sectionn">
            <div className="image-section">
                    <img src="./values.jpg" alt="Values Image" />
                  </div>
        <div className="contenttt">
          <h2>Core Values</h2>
          <p>      
          Innovative solutions drive global empowerment, guided by our core values of authenticity, transparency, and honesty. These values underpin diverse efforts, fostering collaboration and ethical responsibility across all aspects of our company.
          </p>
        </div>
      </div>
    </div>
    




 
<div className="Achi_text" data-aos="zoom-in">
  <h2>Client <a href="#" id="style-2" data-replace="Expertise">
        <span className="onlyc">R</span>
          <span>eview</span></a><p>Our Clients Feedback</p></h2>


      </div>
  
      <div className="App">
      <div className="card-container">
        <div className="card">
          <div className="card-inner">
            <div className="card-front">
            <MDBCardBody className="py-4 mt-2" >
             
              <h5 className="font-weight-bold">Mr. Shashank Mathur</h5>
              <h6 className="font-weight-bold my-3">Sterling Urban Co-operative Bank Ltd</h6>
             
              <p className="mb-2">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                We trust 3HS for their unwavering commitment to security. Their solutions ensure the confidentiality and integrity of our financial data.

                  <MDBIcon fas icon="quote-right" className="pe-2"  />
              </p>
            </MDBCardBody>
         </div>



                  <div className="card-back">
                  <MDBCardBody className="py-4 mt-2" >
              
              <h5 className="font-weight-bold">Mr. Sachin Bhargav</h5>
              <h6 className="font-weight-bold my-3">Process & Machines Automation Systems</h6>
             
              <p className="mb-2">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                3Handshake Innovation Pvt. Ltd. has been our trusted IT support for years they delivered cost-effective solutions without compromising quality. They consistently provide quick, effective, and reliable IT solutions.

                  <MDBIcon fas icon="quote-right" className="pe-2"  />
              </p>
            </MDBCardBody>
                  
                  </div>
          </div>
        </div>



        <div className="card">
          <div className="card-inner">
            <div className="card-front">
            <MDBCardBody className="py-4 mt-2" >
            
              <h5 className="font-weight-bold">Mr. Mohit Bahl</h5>
              <h6 className="font-weight-bold my-3">Cogent 360 Solutions Pvt. Ltd.</h6>
             
              <p className="mb-2">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                3HS delivers excellence! The services of this company are very fast and the quick response time and a complete suite of  all IT solutions.

                  <MDBIcon fas icon="quote-right" className="pe-2"  />
              </p>
            </MDBCardBody>
            </div>
            <div className="card-back">
            <MDBCardBody className="py-4 mt-2" >
              
              <h5 className="font-weight-bold">Mr. Sachin Sharma</h5>
              <h6 className="font-weight-bold my-3">Danish Pvt. Ltd. </h6>
              
              <p className="mb-2">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                3Handshake Innovation Pvt. Ltd.: 5-star expertise in
   camera, biometric, hardware, software, networking, and cloud
 solutions, benefiting Danish Pvt. Ltd.

                  <MDBIcon fas icon="quote-right" className="pe-2"  />
              </p>
            </MDBCardBody>
            </div>
          </div>
        </div>


        <div className="card">
          <div className="card-inner">
            <div className="card-front">
            <MDBCardBody className="py-4 mt-2" >
             
              <h5 className="font-weight-bold">Mr. Arpit Vijayvargiya</h5>
              <h6 className="font-weight-bold my-3">Art India</h6>
              
              <p className="mb-2">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                3Handshake Innovation Pvt. Ltd. consistently provide quick, effective, and reliable IT solutions makes them our favorite service provider. 

                  <MDBIcon fas icon="quote-right" className="pe-2"  />
              </p>
            </MDBCardBody>
            
            </div>
            <div className="card-back">
            <MDBCardBody className="py-4 mt-2" >
            
              <h5 className="font-weight-bold">Mr. Prateek Chaubey</h5>
              <h6 className="font-weight-bold my-3">iGlobe Solutions</h6>
             
              <p className="mb-2">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                We are very satisfied with the services of 3HS. They have well trained IT staff who provide us with IT and Cyber solutions. 3HS exceeds our expectations.

                  <MDBIcon fas icon="quote-right" className="pe-2"  />
              </p>
            </MDBCardBody>
            </div>
          </div>
        </div>
      </div>
    </div>



    
   
    
        </>
    )
}
export default About;