
import React from "react";
import { Link } from "react-router-dom";
import './Banner.scss'


const BannerSlider = ()=>{
    return(<>
       <div id="carouselExampleDark" className="carousel carousel-dark slide margin-top-for-all">
            <div className="carousel-indicators">
                {/* <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button> */}
            </div>
            <div className="carousel-inner">
                    <Link to={'contact'}>
                <div className="carousel-item active" data-bs-interval="10000">
                        <img src="./3-h-2.png" className="d-md-block  d-none w-100 height-100vh" alt="./home-banner-1.jpg" />
                        <img src="./3-h1.png" className="d-block d-md-none w-100 height-100vh" alt="./home-banner-1.jpg" />
                    <div className="carousel-caption d-none d-sm-block text-start">
                        <div>
                            {/* <img className="banner-logo" src="./logo-blue.png" />
                            <h1 className="banner-title">Technology <br/> Made Simple...</h1>
                            <p className="banner-subtitle d-none d-md-block">Reliable, Ready, Responsive</p>
                            <p className="banner-subtitle  d-none d-md-block">Your Safety is Our Priority</p> */}
                            {/* <Link className="btn py-1 banner-button" to={'contact'} >Request a Free Consultation</Link> */}
                        </div>
                    </div>
                </div>
                            </Link>
            </div>
            {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button> */}
        </div>
    </>) 
}

export default BannerSlider;