import "./Network.scss";
import AOS from "aos";
import { useEffect,useState,useRef} from "react";
import { FaLock, FaServer, FaMoneyBillAlt, FaBolt, FaClipboardCheck } from 'react-icons/fa';




const Network = ( ) =>{
  const clientSliderRef = useRef(null);
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);

        // State to track the active tab index
  const [activeIndex, setActiveIndex] = useState(1);

  // Function to handle tab click
  const handleTabClick = (index) => {
    setActiveIndex(index);
  };


  useEffect(() => {
    const clientSlider = clientSliderRef.current;
    
    const interval = setInterval(() => {
      clientSlider.style.transform = "translateX(-20%)";
    }, 3000);

    clientSlider.addEventListener('transitionend', function () {
      clientSlider.appendChild(clientSlider.firstElementChild);
  
      clientSlider.style.transition = "none";
      clientSlider.style.transform = "translateX(0)";
      setTimeout(() => {
        clientSlider.style.transition = "0.3s";
      });
    });

    return () => clearInterval(interval);
  }, []);

    return(
        <>
        <div className="about_text" > 
         <img className="aboutimg" src= './netbg.jpg' alt="xyz" />
            <div className="upperdiv"></div>
            <div className="about"  data-aos="fade-up">
              <h1>Network & Security</h1>
            </div>
         
        </div>



     <div className="parallax-container2" style={{backgroundImage: "url('./network.jpg')"}}>
       
       <div className="parallax-image-right2">
      
     </div>
       <div className="text-container2" data-aos="fade-up">
         <div className="about-us_home2">
           <h2>What is NETWORK & SECURITY ? </h2>
           <p>
           For businesses and consumers, network security is vital. Businesses employ firewalls, encryption, and intrusion detection. Consumers should prioritize strong passwords, regular software updates, and cautious online behavior to mitigate cyber threats.
            </p>
         </div>
       </div>
     </div>



  <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Network & Security "}
            <span className="onlyc">S</span>
            <span>olution</span>
        
          <p>solutions we offer in Network & Security</p>
        </h2>

        
      </div>

    <div className="cyber-container">
      <nav className="cyber-nav">
        <ul className="cyber-ul">
          {/* <li className={activeIndex === 1 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(1)}>
            <i className="fa fa-bicycle"></i></li> */}
          <li className={activeIndex === 1 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(1)}>
          <i className="fa fa-wifi"></i></li>
          {/* <i className="fa-solid fa-tower-broadcast"></i> */}
         
         
         

          <li className={activeIndex === 2 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(2)}>
            <i className="fa fa-wifi"></i></li>

          <li className={activeIndex === 3 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(3)}>
            <i className="fa fa-video"></i></li>

            <li className={activeIndex === 4 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(4)}>
            <i className="fa fa-video"></i>
</li>

        </ul>
      </nav>
      <article className="cyber-article" style={{ display: activeIndex === 1 ? 'block' : 'none' }}>
        <h1>Desig Active-Passive Network</h1>
        <p>Our expert team specializes in designing robust active-passive network solutions tailored to meet your organization's unique requirements. Whether you're looking to enhance network reliability, improve performance, or ensure seamless failover, our solutions integrate the latest technologies and best practices to deliver optimal results.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>                           
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 2 ? 'block' : 'none' }}>
        <h1>Wireless Solution</h1>
        <p>Experience the freedom and flexibility of our cutting-edge wireless solutions designed to empower your business with seamless connectivity. From high-speed Wi-Fi networks to secure guest access portals, we offer comprehensive wireless solutions that enable efficient collaboration, mobility, and productivity across your organization.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 3 ? 'block' : 'none' }}>
        <h1>CCTV Surveillance</h1>
        <p> Ensure the safety and security of your premises with our advanced CCTV surveillance solutions. Our comprehensive offerings include state-of-the-art cameras, intelligent video analytics, and centralized monitoring systems, providing real-time visibility and proactive threat detection to safeguard your assets and personnel.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 4 ? 'block' : 'none' }}>
        <h1>Biometric</h1>
        <p>Embrace the future of access control and identity management with our innovative biometric solutions. Utilizing cutting-edge technologies such as fingerprint recognition, facial recognition, and iris scanning, our biometric solutions offer unparalleled accuracy, reliability, and convenience for securing physical and logical access to your premises and sensitive data.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>

      
      
    </div>


    <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Products "}
            <span className="onlyc">S</span>
            <span>olutions</span>
         
          <p>Products we work with in NETWORK & SECURITY</p>
        </h2>      
    </div>
      
    <section className="our-clients">
      <div className="container">

        <ul ref={clientSliderRef} id="client-slider" className="client-slider">
       

         <li><img src="Grandstream.png" alt="Client 1 Logo" /></li>
          <li><img src="Ruckus.jpeg" alt="Client 2 Logo" /></li>
        <li><img src="Quantum.png" alt="Client 3 Logo" /></li>
          <li><img src="Cisco.jpg" alt="Client 4 Logo" /></li>
          <li><img src="Ubqt.png" alt="Client 1 Logo" /></li>
          <li><img src="Dlinkk.png" alt="Client 2 Logo" /></li>
          <li><img src="Hikvision.png" alt="Client 2 Logo" /></li>
          <li><img src="CpPLus.jpg" alt="Client 2 Logo" /></li>
          
        
          
        </ul>
      </div>
    </section>

<div className="sss">
    <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Benefits of "}
            <span className="onlyc">N</span>
            <span>etwork Security</span>
          
          <p>Unveiling the Benefits of Network Security.</p>
        </h2>      
    </div>
    </div>

<div className='benifts'>
  <div className='beniftsUL'>
    <ul className="cyber-ul">


    <li style={{ '--accent-color': '#60c5a6' }}>
  <div className="icon"><FaLock /></div>
  <div className="title">Data Protection</div>
  <div className="descr">Safeguard sensitive information from unauthorized access and cyber threats, ensuring data confidentiality, integrity, and availability.</div>
</li>


<li style={{ '--accent-color': '#f20071' }}>
  <div className="icon"><FaServer /></div>
  <div className="title">Business Continuity</div>
  <div className="descr">Our secure network infrastructure ensures uninterrupted operations, mitigating risks of downtime, data loss, and system failures, enhancing business resilience and continuity.</div>
</li>
<li style={{ '--accent-color': '#FCBA35' }}>
  <div className="icon"><FaMoneyBillAlt /></div>
  <div className="title">Financial Security</div>
  <div className="descr"> Investing in network security minimizes financial risks from breaches, regulatory fines, legal liabilities, and reputational damage, protecting your bottom line effectively.</div>
</li>
<li style={{ '--accent-color': '#DAE438' }}>
  <div className="icon"><FaBolt /></div>
  <div className="title">Productivity Boost</div>
  <div className="descr">Our secure network fosters productivity by minimizing disruptions, enabling seamless collaboration, and ensuring reliable access to resources and applications for all stakeholders.</div>
</li>
<li style={{ '--accent-color': '#994D7F' }}>
  <div className="icon"><FaClipboardCheck /></div>
  <div className="title">Compliance Assurance</div>
  <div className="descr">Adhering to industry regulations is simpler with our robust network security, helping you avoid penalties and sanctions while safeguarding sensitive data effectively.</div>
</li>
    </ul>
  </div>
 
</div>


        </>
    );
};
export default Network;


