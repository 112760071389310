import "./Cyber.scss";
import AOS from "aos";
import { useEffect,useState,useRef} from "react";
import { FaShieldAlt, FaLock, FaBriefcase, FaHeart, FaMoneyBillAlt } from 'react-icons/fa';



const Cyber = ( ) =>{
  const clientSliderRef = useRef(null);
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);

        // State to track the active tab index
  const [activeIndex, setActiveIndex] = useState(1);

  // Function to handle tab click
  const handleTabClick = (index) => {
    setActiveIndex(index);
  };


  useEffect(() => {
    const clientSlider = clientSliderRef.current;
    
    const interval = setInterval(() => {
      clientSlider.style.transform = "translateX(-20%)";
    }, 3000);

    clientSlider.addEventListener('transitionend', function () {
      clientSlider.appendChild(clientSlider.firstElementChild);
  
      clientSlider.style.transition = "none";
      clientSlider.style.transform = "translateX(0)";
      setTimeout(() => {
        clientSlider.style.transition = "0.3s";
      });
    });

    return () => clearInterval(interval);
  }, []);

    return(
        <>
        <div className="about_text" > 
         <img className="aboutimg" src= './cyy.jpg' alt="xyz" />
            <div className="upperdiv"></div>
            <div className="about"  data-aos="fade-up">
              <h1>Cyber Security</h1>
            </div>
         
        </div>



     <div className="parallax-container2" style={{backgroundImage: "url('./cybr.jpg')"}}>
       
       <div className="parallax-image-right2">
      
     </div>
       <div className="text-container2" data-aos="fade-up">
         <div className="about-us_home2">
           <h2>WHAT IS CYBER SECURITY?</h2>
           <p>
           Digital protection, or information technology security, safeguards electronic systems, devices, and data from malicious attacks. It includes network, application, and data security, crucial in contexts ranging from business to mobile computing. 
                       </p>
         </div>
       </div>
     </div>

{/* 
  <div className="hhh">
      Services we offer in CYBER SECURITY
  </div> */}


  <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Cyber "}
            <span className="onlyc">S</span>
            <span>olutions</span>
        
          <p>Services we offer in CYBER SECURITY</p>
        </h2>

        
      </div>




      <div className="cyber-container">
      <nav className="cyber-nav">
        <ul className="cyber-ul">
          {/* <li className={activeIndex === 1 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(1)}>
            <i className="fa fa-bicycle"></i></li> */}
          <li className={activeIndex === 1 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(1)}>
          <i className="fa fa-fire"></i> </li>
       

          <li className={activeIndex === 2 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(2)}>
            <i className="fa fa-lock"></i></li>

          <li className={activeIndex === 3 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(3)}>
            <i className="fa fa-shield"></i></li>
            
          {/* <li className={activeIndex === 4 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(4)}>
            <i className="fa fa-plane"></i></li> */}
        </ul>
      </nav>
      <article className="cyber-article" style={{ display: activeIndex === 1 ? 'block' : 'none' }}>
        <h1>Firewall/UTM</h1>
        <p>Secure your business network with our Firewall/UTM services. We offer comprehensive protection against cyber threats, including firewall management, intrusion prevention, antivirus, web filtering, application control, VPN, and detailed reporting.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>                           
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 2 ? 'block' : 'none' }}>
        <h1>Endpoint Security</h1>
        <p>Protect your network endpoints with our Endpoint Security solutions. We offer comprehensive protection against malware, ransomware, and other cyber threats.Our solutions include antivirus, firewall, and advanced threat detection to safeguard your endpoints from evolving security risks.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 3 ? 'block' : 'none' }}>
        <h1>DDoS Protection</h1>
        <p> Defend against DDoS attacks with our robust DDoS Protection services. We offer proactive mitigation techniques to ensure uninterrupted availability of your online services. Our solutions include real-time monitoring, traffic analysis, and rapid response to mitigate the impact of DDoS attacks.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>


      {/* <article className="cyber-article" style={{ display: activeIndex === 4 ? 'block' : 'none' }}>
        <h1>Plane</h1>
        <p>The Wright brothers invented and flew the first airplane in 1903, recognized as "the first sustained and controlled heavier-than-air powered flight". They built on the works of George Cayley dating from 1799, who set forth the concept of the modern airplane. Airplanes had a presence in all the major battles of World War II. They were an essential component of the military strategies of the period, such as the American and Japanese aircraft carrier campaigns of the Pacific War.</p>
      </article> */}
      {/* <p className="attribution">Information sourced from <a href="https://www.wikipedia.org/" target="_blank" rel="noopener noreferrer">Wikipedia</a></p> */}
    </div>


    <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Products "}
            <span className="onlyc">S</span>
            <span>olutions</span>
         
          <p>Products we work with including CYBER SECURITY</p>
        </h2>      
    </div>
      
    <section className="our-clients">
      <div className="container">
        <ul ref={clientSliderRef} id="client-slider" className="client-slider">
         <li><img src="checkpoint1.png" alt="Client 1 Logo" /></li>
          <li><img src="sophos.png" alt="Client 2 Logo" /></li>
        <li><img src="fortinet.png" alt="Client 3 Logo" /></li>
          <li><img src="seqrite.png" alt="Client 4 Logo" /></li>
          <li><img src="kaspersky.jpg" alt="Client 4 Logo" /></li>
          <li><img src="McAfee.jpg" alt="Client 4 Logo" /></li>
        </ul>
      </div>
    </section>

<div className="sss">
    <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Benefits of "}
            <span className="onlyc">C</span>
            <span>yber Security</span>
          
          <p>Unveiling the Benefits of Cyber Security.</p>
        </h2>      
    </div>
    </div>

<div className='benifts'>
  <div className='beniftsUL'>
    <ul className="cyber-ul">
    <li style={{ '--accent-color': '#60c5a6' }}>
        <div className="icon"><FaShieldAlt/></div>
        <div className="title">Threats Protection</div>
        <div className="descr">Cybersecurity measures safeguard your digital assets, including sensitive data and systems,from attacks.</div>
      </li>


      
      <li style={{ '--accent-color': '#f20071' }}>
        <div className="icon"><FaLock /></div>
        <div className="title">Data Privacy Assurance</div>
        <div className="descr">Implementing robust cybersecurity protocols ensures the privacy of your data, and preventing unauthorized access.</div>
      </li>
      <li style={{ '--accent-color': '#FCBA35' }}>
        <div className="icon"><FaBriefcase /></div>
        <div className="title">Business Continuity</div>
        <div className="descr">Cyber security measures contribute to uninterrupted business operations, minimizing downtime and ensuring continuity.</div>
      </li>
      <li style={{ '--accent-color': '#DAE438' }}>
        <div className="icon"><FaHeart /></div>
        <div className="title">Trust and Reputation</div>
        <div className="descr">Investing in cybersecurity builds trust and confidence among your customers and enhancing your reputation as a reliable and secure digital realm.</div>
      </li>
      <li style={{ '--accent-color': '#994D7F' }}>
        <div className="icon"><FaMoneyBillAlt /></div>
        <div className="title">Cost Savings</div>
        <div className="descr">Proactive cybersecurity measures help mitigate the financial impact of cyber attacks and avoiding costly data breaches and saving your organization significant resources.</div>
      </li>
    </ul>
  </div>
 
</div>


        </>
    );
};
export default Cyber;


