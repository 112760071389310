import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Form, Button, Alert } from "react-bootstrap";
import "./Contact.scss";

function Contact() {
  const form = useRef();
  const { register, handleSubmit, formState, reset } = useForm();
  const { isSubmitting } = formState;
  const [showAlert, setShowAlert] = useState(false);

  const handleAlertAndReset = () => {
    setShowAlert(true); // Show success alert
    reset(); // Reset form
  };

  return (
    <>
      <div className="about_text">
        <img className="aboutimg" src="./cus3.jpg" alt="xyz" />
        <div className="upperdiv"></div>
        <div className="aboutcontent">
          <h1>Contact Us</h1>
        </div>
      </div>

      <section className="contact-page-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="contact-infoo">
                <div className="contact-infoo-item">
                  <div className="contact-infoo-icon">
                    <i className="fas fa-phone fa-flip-horizontal"></i>
                  </div>
                  <div className="contact-infoo-text">
                    <h2>Call Us</h2>
                    <h5>
                      <a href="tel:+91 722991 5333">Enquiry +91 722991 5333</a>
                    </h5>
                    <h5>
                      <a href="tel:+91 637771 7361">Support +91 637771 7361</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-infoo">
                <div className="contact-infoo-item">
                  <div className="contact-infoo-icon">
                    <i className="fas fa-envelope"></i>
                  </div>
                  <div className="contact-infoo-text">
                    <h2>E-mail</h2>
                    {/* <a href="mailto:enquiry@3handshake.com">enquiry@3handshake.com</a> */}
                    <h5> <a href="mailto:enquiry@3handshake.com">enquiry@3handshake.com</a></h5>
                    <h5> <a href="mailto:support@3handshake.com">support@3handshake.com</a></h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-infoo">
                <div className="contact-infoo-item">
                  <div className="contact-infoo-icon">
                    <i className="fas fa-clock"></i>
                  </div>
                  <div className="contact-infoo-text">
                    <h2>office time</h2>
                    <h5>Mon - Sat 10:00 am - 7.00 pm</h5>
                    <h5>Sun - off</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contact-page-form" method="post">
            <h2>Get in Touch</h2>

   {showAlert && (
              <Alert
                className="mt-4"
                variant="success"
                onClose={() => setShowAlert(false)}
                dismissible
              >
                Response recorded, Thank You!
              </Alert>
            )}



            <Form ref={form} onSubmit={handleSubmit(handleAlertAndReset)}>
              <Form.Group controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="user_name"
                  placeholder="Enter your name"
                  {...register("name", { required: "Name is required" })}
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="user_email"
                  placeholder="Enter email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
              </Form.Group>

              <Form.Group controlId="formBasicMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  rows={5}
                  placeholder="Enter your message"
                  {...register("message", {
                    required: "Message is required",
                  })}
                />
              </Form.Group>

              <Button
                className="cbtn"
                variant="primary"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </Form>

           
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
