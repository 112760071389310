import { useEffect, useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";

import "./Menubar.scss";

function Menubar() {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const winScroll = document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      setScrollPercentage(scrolled);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header>
        <Navbar expand="lg" bg="black" variant="dark" className="Navbar">
          <Container>
            <Navbar.Brand href="/">
              <img src="logo3hs.png" alt="Company Logo" />
            </Navbar.Brand>
            <Navbar.Toggle
              className="toggle"
              aria-controls="basic-navbar-nav"
            />

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <li className="nav-item">
                  <a className="nav-link" href="/" style={{ color: "white" }}>
                    Home
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link "
                    aria-current="page"
                    href="/About"
                    style={{ color: "white" }}
                  >
                    About Us
                  </a>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/Services"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ color: "white" }}
                  >
                    Services
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-itemm dropend">
                      <a
                        className="nav-link dropdown-toggle"
                        href="/Cyber"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Cyber Security
                      </a>
                    </li>

                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle"
                        href="/Data"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Data Security
                      </a>
                    </li>

                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle"
                        href="/Network"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Network & Security
                      </a>
                    </li>

                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle"
                        href="/Cloud"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Cloud Solutions
                      </a>
                    </li>

                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle"
                        href="/Software"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Software Solutions
                      </a>
                    </li>

                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle"
                        href="/Hardware"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Hardware Solutions
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link "
                    aria-current="page"
                    href="/Contact"
                    style={{ color: "white" }}
                  >
                  	Blogs
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link "
                    aria-current="page"
                    href="/Contact"
                    style={{ color: "white" }}
                  >
                  		Gallery

                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link "
                    aria-current="page"
                    href="/Contact"
                    style={{ color: "white" }}
                  >
                    Contact Us
                  </a>
                </li>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div
          className="scroll-line"
          style={{ width: `${scrollPercentage}%` }}
        ></div>
      </header>
    </>
  );
}

export default Menubar;
