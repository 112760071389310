import "./Cloud.scss";
import AOS from "aos";
import { useEffect,useState,useRef} from "react";
import { FaShieldAlt, FaBalanceScale, FaCogs, FaUsers, FaDollarSign } from 'react-icons/fa';



const Cloud = ( ) =>{
  const clientSliderRef = useRef(null);
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);

        // State to track the active tab index
  const [activeIndex, setActiveIndex] = useState(1);

  // Function to handle tab click
  const handleTabClick = (index) => {
    setActiveIndex(index);
  };


  useEffect(() => {
    const clientSlider = clientSliderRef.current;
    
    const interval = setInterval(() => {
      clientSlider.style.transform = "translateX(-20%)";
    }, 3000);

    clientSlider.addEventListener('transitionend', function () {
      clientSlider.appendChild(clientSlider.firstElementChild);
  
      clientSlider.style.transition = "none";
      clientSlider.style.transform = "translateX(0)";
      setTimeout(() => {
        clientSlider.style.transition = "0.3s";
      });
    });

    return () => clearInterval(interval);
  }, []);

    return(
        <>
        <div className="about_text" > 
         <img className="aboutimg" src= './cloudbg.jpg' alt="xyz" />
            <div className="upperdiv"></div>
            <div className="about"  data-aos="fade-up">
              <h1>Cloud Solutions</h1>
            </div>
         
        </div>



     <div className="parallax-container2" style={{backgroundImage: "url('./cloudd.png')"}}>
       
       <div className="parallax-image-right2">
      
     </div>
       <div className="text-container2" data-aos="fade-up">
         <div className="about-us_home2">
           <h2>WHAT IS CLOUD BACKUP?</h2>
           <p>
              Cloud backup is a data protection method where digital files and information are stored remotely on servers accessed via the internet. It works by regularly copying and transferring data from a user's device to a secure cloud storage service, providing a reliable and off-site backup solution to prevent data loss in case of device failure or other emergencies.
                       </p>
         </div>
       </div>
     </div>



  <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Cloud "}
            <span className="onlyc">S</span>
            <span>olutions</span>
        
          <p>solutions we offer in Colud solution</p>
        </h2>

        
      </div>




      <div className="cyber-container">
      <nav className="cyber-nav">
        <ul className="cyber-ul">
          {/* <li className={activeIndex === 1 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(1)}>
            <i className="fa fa-bicycle"></i></li> */}
          <li className={activeIndex === 1 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(1)}>
          <i className="fa fa-cloud"></i> </li>
         

          <li className={activeIndex === 2 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(2)}>
            <i className="fa fa-server"></i></li>

          <li className={activeIndex === 3 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(3)}>
            <i className="fa fa-globe"></i></li>

            <li className={activeIndex === 4 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(4)}>
            <i className="fa fa-shield"></i></li>

            <li className={activeIndex === 5 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(5)}>
            <i className="fa fa-envelope"></i></li>

        </ul>
      </nav>
      <article className="cyber-article" style={{ display: activeIndex === 1 ? 'block' : 'none' }}>
        <h1>Cloud Managed Services</h1>
        <p>Our Cloud Managed Services offer expert management and optimization of your cloud infrastructure on leading platforms such as AWS, Azure, and Alibaba Cloud. With our dedicated team of professionals, we ensure seamless operation, cost optimization, security enhancement, and scalability of your cloud resources, allowing you to focus on your core business activities while leveraging the full potential of cloud computing.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>                           
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 2 ? 'block' : 'none' }}>
        <h1>Cloud Hosting</h1>
        <p>Our Cloud Hosting solutions provide reliable and scalable hosting infrastructure tailored to meet the demands of modern businesses. With high availability, robust security features, and flexible resource allocation, our cloud hosting services ensure optimal performance and seamless scalability for your web applications, websites, and online services.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 3 ? 'block' : 'none' }}>
        <h1>Web Service/Hosting</h1>
        <p> Elevate your online presence with our Web Hosting services designed to provide fast, secure, and reliable hosting solutions for your websites, web applications, and online projects. Benefit from our state-of-the-art infrastructure, proactive security measures, and expert technical support to ensure uninterrupted access and superior performance for your online endeavors.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 4 ? 'block' : 'none' }}>
        <h1>Web-Server Protection</h1>
        <p> Safeguard your online assets with our comprehensive Web Server Protection solutions encompassing website creation and management services. From designing and developing custom websites to ensuring ongoing maintenance, updates, and security enhancements, we offer end-to-end support to keep your online presence secure, optimized, and fully functional.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 5 ? 'block' : 'none' }}>
        <h1>Email Solution</h1>
        <p> Streamline communication and collaboration within your organization with our Email Solution and Web Services. Our email solutions offer secure, feature-rich email hosting, along with integrated productivity tools and seamless integration with other web services. Experience enhanced efficiency, reliability, and security with our tailored email solutions tailored to meet your business needs.
Feel free to customize these descriptions to better fit your brand voice and specific offerings!</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>
      
      
    </div>


    <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Products "}
            <span className="onlyc">S</span>
            <span>olutions</span>
         
          <p>Products we work with in CLOUD SOLUTION</p>
        </h2>      
    </div>
      
    <section className="our-clients">
      <div className="container">

        <ul ref={clientSliderRef} id="client-slider" className="client-slider">
       

         <li><img src="aws.jpg" alt="Client 1 Logo" /></li>
          <li><img src="Azure.jpg" alt="Client 2 Logo" /></li>
        <li><img src="cPanel.png" alt="Client 3 Logo" /></li>
          <li><img src="GCB.png" alt="Client 4 Logo" /></li>
          <li><img src="gSuite.png" alt="Client 1 Logo" /></li>
          <li><img src="O365.png" alt="Client 2 Logo" /></li>
          
        </ul>
      </div>
    </section>

<div className="sss">
    <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Benefits of "}
            <span className="onlyc">C</span>
            <span>loud Solution</span>
          
          <p>Unveiling the Benefits of Cloud Solution.</p>
        </h2>      
    </div>
    </div>

<div className='benifts'>
  <div className='beniftsUL'>
    <ul className="cyber-ul">


    <li style={{ '--accent-color': '#60c5a6' }}>
  <div className="icon"><FaBalanceScale /></div>
  <div className="title">Scalability</div>
  <div className="descr">Cloud solutions offer unparalleled scalability, allowing businesses to easily scale their resources up or down according to changing demands.</div>
</li>
<li style={{ '--accent-color': '#f20071' }}>
  <div className="icon"><FaCogs /></div>
  <div className="title">Flexibility and Agility</div>
  <div className="descr">Cloud solutions provide unmatched flexibility and agility, enabling businesses to adapt quickly to changing market conditions and technological advancements.</div>
</li>
<li style={{ '--accent-color': '#FCBA35' }}>
  <div className="icon"><FaUsers /></div>
  <div className="title">Enhanced Collaboration</div>
  <div className="descr">Cloud solutions facilitate seamless collaboration among teams through shared document communication,and cloud-based platforms, fostering greater productivity and innovation.</div>
</li>
<li style={{ '--accent-color': '#DAE438' }}>
  <div className="icon"><FaShieldAlt /></div>
  <div className="title">Improved Security</div>
  <div className="descr">Leading cloud providers invest in authentication, and access control mechanisms, ensuring that data stored in the cloud remains secure from unauthorized access.</div>
</li>
<li style={{ '--accent-color': '#994D7F' }}>
  <div className="icon"><FaDollarSign /></div>
  <div className="title">Cost Efficiency</div>
  <div className="descr">With cloud solutions, businesses can significantly reduce upfront infrastructure costs by paying only for the resources they use.</div>
</li>
    </ul>
  </div>
 
</div>


        </>
    );
};
export default Cloud;


