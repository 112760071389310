import "./Hardware.scss";
import AOS from "aos";
import { useEffect,useState,useRef} from "react";
import { FaDesktop, FaExpandAlt, FaShieldAlt, FaCoins, FaUserCog } from 'react-icons/fa';



const Hardware = ( ) =>{
  const clientSliderRef = useRef(null);
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);

        // State to track the active tab index
  const [activeIndex, setActiveIndex] = useState(1);

  // Function to handle tab click
  const handleTabClick = (index) => {
    setActiveIndex(index);
  };


  useEffect(() => {
    const clientSlider = clientSliderRef.current;
    
    const interval = setInterval(() => {
      clientSlider.style.transform = "translateX(-20%)";
    }, 3000);

    clientSlider.addEventListener('transitionend', function () {
      clientSlider.appendChild(clientSlider.firstElementChild);
  
      clientSlider.style.transition = "none";
      clientSlider.style.transform = "translateX(0)";
      setTimeout(() => {
        clientSlider.style.transition = "0.3s";
      });
    });

    return () => clearInterval(interval);
  }, []);

    return(
        <>
        <div className="about_text" > 
         <img className="aboutimg" src= './hardbg.avif' alt="xyz" />
            <div className="upperdiv"></div>
            <div className="about"  data-aos="fade-up">
              <h1>Hardware Solutions</h1>
            </div>
         
        </div>



     <div className="parallax-container2" style={{backgroundImage: "url('./hardwaree.jpg')"}}>
       
       <div className="parallax-image-right2">
      
     </div>
       <div className="text-container2" data-aos="fade-up">
         <div className="about-us_home2">
           <h2>WHAT IS HARDWARE SOLUTIONS? </h2>
           <p>
           A hardware solution refers to a physical device or component designed to address a specific problem or perform a particular function. Examples include computer hardware, routers, and security tokens, providing tangible tools to meet various technological needs.
            </p>
         </div>
       </div>
     </div>



  <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Hardware "}
            <span className="onlyc">S</span>
            <span>olutions</span>
        
          <p>solutions we offer in Hardware Solutions</p>
        </h2>

        
      </div>

    <div className="cyber-container">
      <nav className="cyber-nav">
        <ul className="cyber-ul">

          <li className={activeIndex === 1 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(1)}>
          <i className="fa fa-desktop"></i></li>
      
          <li className={activeIndex === 2 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(2)}>
          <i className="fa fa-laptop"></i></li>

          <li className={activeIndex === 3 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(3)}>
            <i className="fa fa-server"></i></li>

            <li className={activeIndex === 4 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(4)}>
            <i className="fa fa-gamepad"></i></li>

            <li className={activeIndex === 5 ? 'cyber-li active' : 'cyber-li'} onClick={() => handleTabClick(5)}>
            <i className="fa fa-hdd"></i></li>

        </ul>
      </nav>
      <article className="cyber-article" style={{ display: activeIndex === 1 ? 'block' : 'none' }}>
        <h1>Desktop</h1>
        <p>Whether you need a powerful workstation for your office or a versatile desktop for home use, we offer a wide range of desktop computers tailored to your needs. From compact designs perfect for tight spaces to high-performance rigs for demanding tasks, our desktops deliver reliability and performance. Need assistance with setup, upgrades, or repairs? Our expert technicians are here to help you get the most out of your desktop computing experience. </p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>                           
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 2 ? 'block' : 'none' }}>
        <h1>Laptop</h1>
        <p>Stay productive on the go with our selection of sleek and portable laptops. Whether you're a student, professional, or casual user, we have the perfect laptop to match your lifestyle and budget. From lightweight ultrabooks to robust gaming laptops, our extensive collection ensures there's something for everyone. Plus, with our comprehensive repair services, you can count on us to keep your laptop running smoothly and efficiently. </p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 3 ? 'block' : 'none' }}>
        <h1>Server</h1>
        <p>Power your business with our robust server solutions designed for reliability and performance. Whether you need a small-scale server for local operations or a scalable solution for enterprise-level applications, we provide tailored options to meet your needs. Our services include installation, configuration, and ongoing maintenance to keep your server infrastructure running at peak efficiency.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>

      <article className="cyber-article" style={{ display: activeIndex === 4 ? 'block' : 'none' }}>
        <h1>Gaming PC</h1>
        <p>Experience the ultimate gaming performance with our custom-built gaming PCs. Designed to deliver unrivaled graphics, speed, and responsiveness, our gaming rigs are built to handle even the most demanding titles with ease. Whether you're a casual gamer or a hardcore enthusiast, our gaming PCs offer the power and flexibility to take your gaming experience to the next level. Need upgrades or repairs? Our gaming experts are here to ensure your rig stays optimized for peak performance.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>


      <article className="cyber-article" style={{ display: activeIndex === 5 ? 'block' : 'none' }}>
        <h1>Storage Devices</h1>
        <p>Get the storage solutions you need to keep your data safe and accessible. Whether you require additional storage for personal files or a scalable storage solution for your business, we offer a variety of devices to meet your requirements. From external hard drives to network-attached storage (NAS) systems, we provide reliable storage options to safeguard your valuable data.</p>
        <button><a href="tel:+91 722991 5333" >Contact</a></button> 
        <button><a href="mailto:enquiry@3handshake.com">Enquiry</a></button>  
      </article>
      
      
    </div>

    <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Products "}
            <span className="onlyc">S</span>
            <span>olutions</span>
         
          <p>Products we work with in HARDWARE SOLUTIONS</p>
        </h2>      
    </div>
      
    <section className="our-clients">
      <div className="container">

        <ul ref={clientSliderRef} id="client-slider" className="client-slider">
       

         <li><img src="Dell.png" alt="Client 1 Logo" /></li>
          <li><img src="hp.png" alt="Client 2 Logo" /></li>
        <li><img src="lenovo.jpg" alt="Client 3 Logo" /></li>
          <li><img src="thinkpad.jpg" alt="Client 4 Logo" /></li>
          <li><img src="acerr.png" alt="Client 1 Logo" /></li>
          
        </ul>
      </div>
    </section>

<div className="sss">
    <div className="Achi_text" data-aos="fade-up">
        <h2>
          
          {"Benefits of "}
            <span className="onlyc">H</span>
            <span>ardware Solution</span>
          
          <p>Unveiling the Benefits of Hardware Solution.</p>
        </h2>      
    </div>
    </div>

<div className='benifts'>
  <div className='beniftsUL'>
    <ul className="cyber-ul">


    <li style={{ '--accent-color': '#60c5a6' }}>
  <div className="icon"><FaDesktop /></div>
  <div className="title">Improved Performance</div>
  <div className="descr">Upgrade to modern hardware for faster processing speeds and smoother multitasking, boosting overall system efficiency.</div>
</li>


<li style={{ '--accent-color': '#f20071' }}>
  <div className="icon"><FaExpandAlt /></div>
  <div className="title">Scalability and Flexibility</div>
  <div className="descr">Adapt to changing needs with hardware solutions that offer easy expansion, upgrades, and customization, ensuring scalability as your organization grows.</div>
</li>
<li style={{ '--accent-color': '#FCBA35' }}>
  <div className="icon"><FaShieldAlt /></div>
  <div className="title">Better Security Features</div>
  <div className="descr"> Stay protected with advanced security measures like encryption and biometric authentication, safeguarding against cyber threats and data breaches.</div>
</li>
<li style={{ '--accent-color': '#DAE438' }}>
  <div className="icon"><FaCoins /></div>
  <div className="title">Cost Efficiency</div>
  <div className="descr">Invest in quality hardware for lower total cost of ownership, minimizing maintenance expenses and increasing productivity over time.</div>
</li>
<li style={{ '--accent-color': '#994D7F' }}>
  <div className="icon"><FaUserCog /></div>
  <div className="title">Optimized User Experience</div>
  <div className="descr">Streamline tasks and improve usability with hardware solutions designed for intuitive interfaces and seamless integration with software applications.</div>
</li>
    </ul>
  </div>
 
</div>


        </>
    );
};
export default Hardware;


